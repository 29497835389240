export const carouselState = ({
  carouselListWidth,
  carouselPadding,
  carouselWidth,
  slideActive,
  slidesTotal,
}: {
  carouselListWidth: number;
  carouselPadding: number;
  carouselWidth: number;
  slideActive: number;
  slidesTotal: number;
}) => {
  const paginationShow = carouselListWidth > carouselWidth - carouselPadding;
  if (carouselWidth <= 767) {
    return {
      slideWidth: 250,
      slidesPerPage: 1,
      slideActive,
      paginationPage: slideActive + 1,
      paginationCount: slidesTotal,
      paginationShow,
    };
  }

  if (carouselWidth >= 768 && carouselWidth <= 1023) {
    const slideWidth = 300;
    const slidesPerPage = 2;
    const paginationCount = Math.ceil(slidesTotal / slidesPerPage);
    const _slideActive = Math.floor(slideActive / slidesPerPage) * slidesPerPage;
    const paginationPage = _slideActive / slidesPerPage + 1;
    return {
      slideWidth,
      slidesPerPage,
      slideActive: _slideActive,
      paginationPage,
      paginationCount,
      paginationShow,
    };
  }

  if (carouselWidth >= 1024 && carouselWidth <= 1439) {
    const slideWidth = 300;
    const slidesPerPage = 3;
    const paginationCount = Math.ceil(slidesTotal / slidesPerPage);
    const _slideActive = Math.floor(slideActive / slidesPerPage) * slidesPerPage;
    const paginationPage = _slideActive / slidesPerPage + 1;
    return {
      slideWidth,
      slidesPerPage,
      slideActive: _slideActive,
      paginationPage,
      paginationCount,
      paginationShow,
    };
  }

  if (carouselWidth >= 1440 && carouselWidth <= 1919) {
    const slideWidth = 300;
    const slidesPerPage = 4;
    const paginationCount = Math.ceil(slidesTotal / slidesPerPage);

    const _slideActive = Math.floor(slideActive / slidesPerPage) * slidesPerPage;
    const paginationPage = _slideActive / slidesPerPage + 1;

    return {
      slideWidth,
      slidesPerPage,
      slideActive: _slideActive,
      paginationPage,
      paginationCount,
      paginationShow,
    };
  }

  if (carouselWidth >= 1920) {
    const slideWidth = 300;
    const slidesPerPage = 5;
    const paginationCount = Math.ceil(slidesTotal / slidesPerPage);
    const _slideActive = Math.floor(slideActive / slidesPerPage) * slidesPerPage;
    const paginationPage = _slideActive / slidesPerPage + 1;
    return {
      slideWidth,
      slidesPerPage,
      slideActive: _slideActive,
      paginationPage,
      paginationCount,
      paginationShow,
    };
  }

  return {
    slideWidth: 250,
    slidesPerPage: 1,
    slideActive: 0,
    paginationPage: 1,
    paginationCount: 1,
    paginationShow,
  };
};
