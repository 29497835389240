import React, { useRef, useState, useEffect } from 'react';
import { renderTextWithFootnotesReferences } from '@oneaudi/feature-app-utils';
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide';

import {
  Button as AudiButton,
  Text as AudiText,
  Pagination as AudiPagination,
  useStyledTheme,
} from '@audi/audi-ui-react';

import { useIsServer } from './useIsServer';
import { carouselState } from '../carouselState';
import { urlQueryStringToObj } from '../urlQueryStringToObj';
import { objToStr } from '../objToStr';

import '@splidejs/splide/dist/css/splide-core.min.css';
import './faCarousel.css';

const carouselSlides = (slides: ContentApp['slides'], slideWidth: number) => {
  return slides.map((slide, index) => {
    const {
      slideTitle,
      slideImageAssetPath,
      slideImageAlt,
      slideDescription,
      slideDisclaimer,
      slideCtaText,
      slideCtaUrl,
      slideCtaUrlTarget,
    } = slide;
    const key = `${slideTitle || 'slideTitle'}:${slideImageAlt || 'slideImageAlt'}:${slideImageAssetPath || 'slideImageAssetPath'}:${index}`;

    const [slideImageSrc, slideImageSrcQueryParams] = slideImageAssetPath.split('?');
    const slideImageSrcQueryParamsObj = urlQueryStringToObj(slideImageSrcQueryParams);
    slideImageSrcQueryParamsObj.preferwebp = 'true';
    slideImageSrcQueryParamsObj.width = `${slideWidth}`;
    const imgSrc = `${slideImageSrc}?${objToStr(slideImageSrcQueryParamsObj)}`;

    return (
      <SplideSlide key={key}>
        <div
          data-testid="CarouselSlide"
          className="faCarousel__slide"
          style={{ width: `${slideWidth}px` }}
        >
          <img
            data-testid="CarouselSlideImage"
            className="faCarousel__image"
            src={imgSrc}
            alt={slideImageAlt}
            width={slideWidth}
            height={slideWidth}
          />
          {(slideTitle || slideDescription) && (
            <div className="faCarousel__copy">
              {slideTitle && (
                <AudiText
                  variant="copy1"
                  weight="bold"
                  data-testid="CarouselSlideTitle"
                  className="faCarousel__slideTitle"
                >
                  {renderTextWithFootnotesReferences(slideTitle)}
                </AudiText>
              )}

              {slideDescription && (
                <AudiText
                  variant="copy1"
                  data-testid="CarouselSlideDescription"
                  className="faCarousel__slideDescription"
                >
                  {renderTextWithFootnotesReferences(slideDescription)}
                </AudiText>
              )}
            </div>
          )}

          {slideCtaUrl && slideCtaText && (
            <AudiButton
              data-testid="CarouselSlideCta"
              className="faCarousel__cta"
              variant="text"
              size="small"
              href={slideCtaUrl}
              newWindow={slideCtaUrlTarget === '2'}
            >
              {renderTextWithFootnotesReferences(slideCtaText)}
            </AudiButton>
          )}

          {slideDisclaimer && (
            <AudiText
              variant="copy2"
              data-testid="CarouselSlideDisclaimer"
              className="faCarousel__slideDisclaimer"
            >
              {renderTextWithFootnotesReferences(slideDisclaimer)}
            </AudiText>
          )}
        </div>
      </SplideSlide>
    );
  });
};

export const Carousel = ({ data }: { data: ContentApp }) => {
  const { carouselTitle, slides } = data;

  const refSplide = useRef<Splide>(null);
  const refSlideTrack = useRef<HTMLDivElement>(null);
  const [nodePagination, setNodePagination] = useState<HTMLDivElement>();

  const isServer = useIsServer();

  const [carouselWidth, setCarouselWidth] = useState(0);
  const [carouselPadding, setCarouselPadding] = useState(0);
  const [slideWidth, setSlideWidth] = useState(250);
  const [slideActive, setSlideActive] = useState(0);
  const [slidesPerPage, setSlidesPerPage] = useState(1);
  const [paginationShow, setPaginationShow] = useState(true);
  const [paginationCount, setPaginationCount] = useState(1);
  const [paginationPage, setPaginationPage] = useState(1);

  const slideGap = 24;

  const updateCarouselState = (padding: number, targetSlide?: number) => {
    if (!refSlideTrack?.current || !refSplide?.current?.splide) {
      throw new Error('Missing refSlideTrack or refSplide');
    }
    // Get new Carousel state based on width
    const out = carouselState({
      carouselWidth: refSlideTrack.current.clientWidth,
      carouselListWidth: refSlideTrack.current.querySelector('.splide__list')?.scrollWidth || 0,
      carouselPadding: padding,
      slideActive:
        typeof targetSlide === 'undefined' ? refSplide.current.splide.index : targetSlide,
      slidesTotal: slides.length,
    });

    // Update state
    setCarouselWidth(refSlideTrack.current.clientWidth);
    setSlideWidth(out.slideWidth);
    setSlideActive(out.slideActive);
    setSlidesPerPage(out.slidesPerPage);
    setPaginationCount(out.paginationCount);
    setPaginationPage(out.paginationPage);
    setPaginationShow(out.paginationShow);

    return out.slideActive;
  };

  // Connect Audi Pagination to Splide Carousel
  useEffect(() => {
    const onClickNext = () => {
      if (refSplide?.current?.splide) {
        const newSlide = updateCarouselState(
          carouselPadding,
          refSplide.current.splide.index + slidesPerPage,
        );
        // Move carousel
        refSplide.current.splide.go(newSlide);
      }
    };
    const onClickPrev = () => {
      if (refSplide?.current?.splide) {
        const newSlide = updateCarouselState(
          carouselPadding,
          refSplide.current.splide.index - slidesPerPage,
        );
        // Move carousel
        refSplide.current.splide.go(newSlide);
      }
    };
    if (nodePagination) {
      const buttonNext = nodePagination.querySelector('button[aria-label="Next page"]');
      if (buttonNext) {
        buttonNext.addEventListener('click', onClickNext);
      }
      const buttonPrev = nodePagination.querySelector('button[aria-label="Previous page"]');
      if (buttonPrev) {
        if (buttonPrev) {
          buttonPrev.addEventListener('click', onClickPrev);
        }
      }
    }
    // Cleanup
    return () => {
      if (nodePagination) {
        const buttonNext = nodePagination.querySelector('button[aria-label="Next page"]');
        if (buttonNext) {
          buttonNext.removeEventListener('click', onClickNext);
        }
        const buttonPrev = nodePagination.querySelector('button[aria-label="Previous page"]');
        if (buttonPrev) {
          if (buttonPrev) {
            buttonPrev.removeEventListener('click', onClickPrev);
          }
        }
      }
    };
  }, [nodePagination, slidesPerPage, carouselPadding]);

  // Update Carousel state on resize
  useEffect(() => {
    if (refSplide?.current?.splide) {
      refSplide.current.splide.on('resized', () => {
        if (refSlideTrack?.current?.clientWidth !== carouselWidth) {
          updateCarouselState(carouselPadding);
        }
      });
    }
    // Cleanup
    return () => {
      if (refSplide?.current?.splide) {
        refSplide.current.splide.off('resized');
      }
    };
  }, [refSplide, carouselPadding]);

  useEffect(() => {
    if (refSplide?.current?.splide) {
      refSplide.current.splide.on('updated', ({ padding }) => {
        const { right = 0 } = padding as { [key: string]: number };
        setCarouselPadding(right);
      });
    }
    // Cleanup
    return () => {
      if (refSplide?.current?.splide) {
        refSplide.current.splide.off('updated');
      }
    };
  }, [refSplide]);

  // Update Carousel state on drag
  useEffect(() => {
    if (refSplide?.current?.splide) {
      refSplide.current.splide.on('active', () => {
        if (refSplide?.current?.splide) {
          updateCarouselState(carouselPadding, refSplide.current.splide.index);
        }
      });
    }
    // Cleanup
    return () => {
      if (refSplide?.current?.splide) {
        refSplide.current.splide.off('active');
      }
    };
  }, [refSplide, slideActive]);

  // Update Carousel state on load
  useEffect(() => {
    updateCarouselState(carouselPadding);
  }, []);

  // Get class names
  const rootClassNames = [];

  const theme = useStyledTheme();
  if (theme.name) {
    const themeName = theme.name.toLowerCase();
    if (themeName.includes('light')) {
      rootClassNames.push('faCarousel--light');
    }
    if (themeName.includes('dark')) {
      rootClassNames.push('faCarousel--dark');
    }
  }
  rootClassNames.push(carouselTitle ? 'faCarousel--withTitle' : 'faCarousel--withoutTitle');

  // Get slides
  const splideSlides = carouselSlides(slides, slideWidth);

  return (
    <section
      data-testid="Carousel"
      aria-label={carouselTitle || 'Carousel'}
      className={`faCarousel ${rootClassNames.join(' ')}`}
    >
      <AudiText
        data-testid="CarouselTitle"
        className={`faCarousel__title ${carouselTitle ? 'faCarousel__title--set' : 'faCarousel__title--unset'}`}
        variant="order2"
        weight="bold"
        as="h2"
      >
        {renderTextWithFootnotesReferences(carouselTitle || '')}
      </AudiText>

      <div className="faCarousel__slideTrack" ref={refSlideTrack}>
        <Splide
          hasTrack={false}
          options={{
            pagination: false,
            arrows: false,
            gap: slideGap,
            autoWidth: true,
            fixedWidth: slideWidth,
            start: slideActive,
            breakpoints: {
              374: {
                padding: { left: 16, right: 16 },
              },
              767: {
                padding: { left: 28, right: 28 },
              },
              1023: {
                padding: { left: 40, right: 40 },
              },
              1439: {
                padding: { left: 40, right: 40 },
              },
              1919: {
                padding: { left: 96, right: 96 },
              },
              1920000: {
                padding: { left: 96, right: 96 },
              },
            },
          }}
          ref={refSplide}
        >
          <SplideTrack>{splideSlides}</SplideTrack>
        </Splide>
      </div>
      <div
        data-testid="CarouselPaginationContainer"
        className={`faCarousel__paginationContainer ${paginationShow ? 'faCarousel__paginationContainer--visible' : 'faCarousel__paginationContainer--hidden'}`}
      >
        {isServer === false && (
          <AudiPagination
            ref={(node: HTMLDivElement) => setNodePagination(node)}
            page={paginationPage}
            count={paginationCount}
            data-testid="CarouselPagination"
            className="faCarousel__paginationComponent"
            variant="compact"
          />
        )}
      </div>
    </section>
  );
};
